import { Navigate, Route, Routes, useRoutes } from 'react-router-dom';

// routes
import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import MainLayout from 'layout/MainLayout';
import { useSelector } from 'react-redux';

const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const ProductsPage = Loadable(lazy(() => import('views/products')));
const ProductsDetailsPage = Loadable(lazy(() => import('views/products/product-details')));
const OrderDetailsPage = Loadable(lazy(() => import('views/orders/order-details')));


const CartPage = Loadable(lazy(() => import('views/cart')));
const OrdersPage = Loadable(lazy(() => import('views/orders')));



const AuthGuard = Loadable(lazy(() => import('routes/AuthGuard')));
const AgentCheck = Loadable(lazy(() => import('routes/AgentCheck')));


// =================
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const token = useSelector((state) => state.auth.token);
  return (
    <Routes>
      <Route path="/">
        <Route path="/:username">
          <Route path="auth" element={<MinimalLayout />}>
            <Route path="login" element={<AuthLogin3 />} />
            <Route path="register" element={<AuthRegister3 />} />
            <Route path='' element={<Navigate to='login' />} />
          </Route>
          <Route element={<AuthGuard token={token} />}>
            <Route element={<MainLayout />}>
              <Route element={<AgentCheck />}>
                <Route path="shop" >
                  <Route path="products" element={<ProductsPage />} />
                  <Route path="products/:productId" element={<ProductsDetailsPage />} />
                  <Route path="orders/:ref" element={<OrderDetailsPage />} />

                  <Route path="cart" element={<CartPage />} />
                  <Route path="orders" element={<OrdersPage />} />

                  <Route path='' element={<Navigate to='products' />} />
                </Route>
              </Route>
            </Route>

            <Route path='' element={<Navigate to='shop' />} />
          </Route>
        </Route>
        <Route path='' element={<Navigate to='public' />} />
      </Route>
    </Routes>
  )
}
