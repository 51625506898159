import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, List, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import { useGetAgentInfosQuery } from 'apis/mlm.api';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
  const theme = useTheme();
  const token = useSelector((state) => state.auth.token);
  const { quantity : nbItemsInCart } = useSelector(state => state.cart);
  // menu list collapse & items
  const items = item.children?.map((menu)=>{
      // if(token==null && menu.id === "orders" ) menu.disabled = true;
      if(!token && menu.id === "orders" )  menu.disabled = true;
      else menu.disabled = false;
      
      if(menu.id === "cart" )menu.chip = {
        //color:"primary",
        //variant:"outlined",
        label: nbItemsInCart
      };
      return menu;
    }).map((menu) => {
      switch (menu.type) {
        case 'collapse':
          return <NavCollapse key={menu.id} menu={menu} level={1} />;
        case 'item':
          return <NavItem key={menu.id} item={menu} level={1}/>;
        default:
          return (
            <Typography key={menu.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    });

  return (
    <>
      <List
        subheader={
          item.title && (
            <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
              {item.title}
              {item.caption && (
                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                  {item.caption}
                </Typography>
              )}
            </Typography>
          )
        }
      >
        {items}
      </List>

      {/* group divider */}
      <Divider sx={{ mt: 0.25, mb: 1.25 }} />
    </>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object
};

export default NavGroup;
