import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import authReducer from 'slices/auth.slice';
import cartReducer from 'slices/cart.slice';
import wishlistReducer from 'slices/wishlist.slice';

import { authApi } from 'apis/auth.api'; 
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/es/storage/session';
import { mlmApi } from 'apis/mlm.api';
import { stripeApi } from 'apis/stripe.api';
import { publicApi } from 'apis/public.api';
// ==============================|| COMBINE REDUCER ||============================== //

const authPersistedReducer = persistReducer({
  key: 'auth',
  storage:sessionStorage,
}, authReducer)

const cartPersistedReducer = persistReducer({
  key: 'cart',
  storage:sessionStorage,
}, cartReducer)

const reducer = combineReducers({
  customization: customizationReducer,
  auth : authPersistedReducer,
  cart : cartPersistedReducer,
  wishlist : wishlistReducer,

  [authApi.reducerPath]: authApi.reducer,
  [mlmApi.reducerPath]: mlmApi.reducer,
  [stripeApi.reducerPath]: stripeApi.reducer,
  [publicApi.reducerPath]: publicApi.reducer,



});

export default reducer;
