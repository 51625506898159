import { useLoginMutation, useRegisterMutation } from 'apis/auth.api';
import { useDispatch } from 'react-redux';
import { setUserToken } from 'slices/auth.slice';
import { useNavigate, useParams } from 'react-router-dom';
import { mlmApi } from 'apis/mlm.api';
import { stripeApi } from 'apis/stripe.api';
import { clearCart } from 'slices/cart.slice';
export const useAuthService = () => {
  const navigate = useNavigate();
  const [loginMutation] = useLoginMutation();
  const [registerMutation] = useRegisterMutation();

  const dispatch = useDispatch();
  const params = useParams();
  const logout = async () => {
    try {
      await dispatch(mlmApi.util.resetApiState())
      await dispatch(stripeApi.util.resetApiState())
      await dispatch(clearCart());

      await dispatch(setUserToken(null));
    } catch (error) {
      throw error;
    }
  };

  const login = async (credentials, to = null) => {
    try {
      const result = await loginMutation(credentials).unwrap();
      await dispatch(setUserToken(result.token));
      navigate(to ? to : `/${params.username}`);
      return result;
    } catch (error) {
      throw error;
    }
  };

  const register = async (userData) => {
    try {
      const result = await registerMutation(userData).unwrap();
      dispatch(setUserToken(result.token));
      navigate(`/${params.username}`);
      return result;
    } catch (error) {
      throw error;
    }
  };

  return { login, register, logout};
};