import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";


const initialState = {
    cartItems: [],
    quantity: 0,
    total: 0,
    isLoading: true,
}

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        clearCart: (state) => {
            state.cartItems = [];
            state.quantity= 0;
            state.total= 0;
        },
        removeItem: (state, action) => {
            const itemId = action.payload;
            const cartItem = state.cartItems.find(item => item.id === itemId);
            state.cartItems = state.cartItems.filter((item) => item.id !== itemId)
            cartSlice.caseReducers.calculateTotals(state);
            // toast.error('Product removed from the cart!');
            if(cartItem) toast(`${cartItem.name} a été supprimé au panier.`);
        },
        updateCartAmount: (state, action) => {
            const cartItem = state.cartItems.find(item => item.id === action.payload.id);
            cartItem.quantity = Number(action.payload.quantity);
            cartSlice.caseReducers.calculateTotals(state);
        },
        calculateTotals: (state) => {
            let quantity = 0;
            let total = 0;
            state.cartItems.forEach(item => {
                quantity += parseInt(item.quantity);
                total += parseInt(item.quantity) * parseFloat(item.unitPrice) * (1-item.discountRate);
            });
            state.quantity = quantity;
            state.total = total;
        },
        addToCart: (state, action) => {
            console.log("Adding product", action.payload);
            let cartItem = state.cartItems.find(item => item.id === action.payload.id);
            if(!cartItem){
                cartItem = action.payload;
                state.cartItems.push(cartItem);
            }else{
                cartItem.quantity += action.payload.quantity;
            }
            cartSlice.caseReducers.calculateTotals(state);
            //toast.success('Product added to the cart!');
            toast(`${action.payload.quantity} ${cartItem.name} ajouté(s) au panier.`);

        }
    }
})

// console.log(cartSlice);
export const { clearCart, removeItem, updateCartAmount, decrease, calculateTotals, addToCart } = cartSlice.actions;

export default cartSlice.reducer;