// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import { IconDashboard, IconBasket, IconNetwork, IconList, IconShoppingBag } from '@tabler/icons-react';
import { useParams } from 'react-router';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const params = useParams();
  const shop = {
    id: 'shop',
    title: 'Boutique',
    type: 'group',
    children: [
      {
        id: 'products',
        title: 'Tous les produits',
        type: 'item',
        url: `/${params.username}/shop/products`,
        icon: IconBasket,
        breadcrumbs: false
      },
      {
        id: 'cart',
        title: 'Mon panier',
        type: 'item',
        url: `/${params.username}/shop/cart`,
        icon: IconShoppingBag,
        breadcrumbs: false
      },
      {
        id: 'orders',
        title: 'Mes commandes',
        type: 'item',
        url: `/${params.username}/shop/orders`,
        icon: IconList,
        breadcrumbs: false
      }
    ]
  };

  const menuItems = {
    items: [shop]
  };
  const navItems = menuItems.items.map((item) => {
    
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item}/>;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
